@charset "utf-8";

@use "sass:color";

// Define defaults for each variable.

$base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$text-color:       #111 !default;
$background-color: #EEE !default;
$brand-color:      #111 !default;
$link-color:       #3b6d78 !default;

$grey-color:       #828282 !default;
$grey-color-light: color.adjust($grey-color, $lightness: 40%) !default;
$grey-color-dark:  color.adjust($grey-color, $lightness: -25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    960px !default;

$on-palm:          600px !default;
$on-laptop:        960px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting"
;


.gwd-p-opnc {
  // background-image: -webkit-radial-gradient(center center, circle cover, rgb(255, 255, 255) 0%, rgb(128, 128, 128) 99%);
  background-image: url('background.jpg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-color: black;
}

.gwd-p-opnc a {
  font-family: "Kaushan Script";
  font-size: 60px;
  text-align: center;
  display: block;
  text-decoration: none;
}

.gwd-p-opnc {
  a:link, a:visited, a:hover {
    color: #EEE;
  }
}

.home_grid {
  margin: 0 auto;
  display: grid;
  grid-gap: 2rem;

  .grid_item {
    align-self: center;
    justify-self: center;

    .item_group {
      font-family: "Kaushan Script";
      font-size: 1.5rem;
      padding: 1rem;
      text-align: center;
    }
  }

  img {
    border-radius: 8px;
  }
}

.group_grid {
  margin: 0 auto;
  display: grid;
  grid-gap: 2rem;

  .grid_item {
    align-self: center;
    justify-self: center;
    padding-top: 1rem;

    p.title {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      text-align: center;
      font-size: 1.5rem;

      span.title {
        font-family: "Kaushan Script";
      }
    }

    .desc {
      margin-top: 0.5rem;
      text-align: center;
    }

    .notes {
      margin-top: 0.5rem;
      text-align: center;
      font-weight: bold;
      font-style: italic;
    }

    .sold_oval {
      background-color: #1468a8;
      border-radius: 50%;
      color: white;
      font-size: 1.5rem;
      position:relative;
      bottom: -0.25rem;

      .sold {
        position:relative;
        bottom: 0.25rem;
        font-size: 1rem;
      }
    }

  }
}

.shop_grid {
  margin: 0 auto;
  display: grid;
  grid-gap: 2rem;

  .grid_item {
    align-self: center;
    justify-self: center;
    padding-top: 1rem;

    .desc {
      margin-top: 1rem;
      text-align: center;
    }

  }

  img {
    border-radius: 8px;
  }
}

.irina_moroz {
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    margin-top: 2rem;
    text-align: justify;
    text-justify: auto;
  }
}

@media (min-width: 600px) {
  .home_grid { grid-template-columns: repeat(2, 1fr); }
  .group_grid { grid-template-columns: repeat(2, 1fr); }
  .shop_grid { grid-template-columns: repeat(2, 1fr); }
}

ul.shop {
  list-style-type: none;
  li {
    padding: 0.5rem;
  }
}

//Firefox glitch
// @font-face {
//   font-family: 'Kaushan Script';
//   font-style: normal;
//   font-weight: 400;
//   src: local('Kaushan Script'), local('KaushanScript-Regular'), url($baseurl + '/assets/fonts/kaushan_script.ttf') format('truetype');
// }

@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');
